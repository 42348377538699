import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import { isEmpty } from "lodash";
import TierUserForm from "./TierUserForm";

type TierUpdateButtonProps = {
  userId?: string;
  tierId: string;
};

const TierUserUpdateButton = ({
  userId,
  tierId,
}: TierUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(userId)
    ? "Add User To Tier+"
    : "Remove From Tier";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <TierUserForm onClose={toggleFullScreen} userId={userId} tierId={tierId} />
  ) : (
    <Button variant="solid" colorScheme="cyan" onClick={toggleFullScreen}>
      {updateLabel}
    </Button>
  );
};

export default TierUserUpdateButton;

import { useCallback, useEffect, useState } from "react";
import { countryService } from "../../../services/countryService";
import { showErrorMessage } from "../../../utils/showErrorMessage";
import { showSuccessMessage } from "../../../utils/showSuccessMessage";
import { identity, pickBy } from "lodash";
import { AfriexCountry, AfriexQueryParams } from "../../../types";
import { replaceUnderScoreIds } from "../../../utils/replaceUnderScoreIds";

const useCountryUpdate = () => {
  const [list, setList] = useState<AfriexCountry[]>([] as any);
  const [item, setItem] = useState<AfriexCountry>({} as any);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [shouldShowForm, setShouldShowForm] = useState<boolean>(false);
  const [filters, setFilters] = useState<AfriexQueryParams>(
    {} as AfriexQueryParams
  );

  list?.sort((a, b) => a?.name?.localeCompare(b.name));

  const fetchList = useCallback(
    async (params: AfriexQueryParams) => {
      try {
        setIsLoading(true);
        const { data: items, total: itemsTotal } = await countryService.getList(
          { ...params, ...filters }
        );
        setList(replaceUnderScoreIds(items));
        setTotal(itemsTotal ?? 0);
      } catch (e) {
        showErrorMessage(`Fetching country list failed:${e as any}`);
      } finally {
        setIsLoading(false);
      }
    },
    [filters]
  );

  const fetchItem = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      const item = await countryService.getItem(id);
      setItem(item);
    } catch (e) {
      showErrorMessage(`Fetching country details failed:${e as any}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFilters = useCallback(async (filters: AfriexQueryParams) => {
    setIsSearch(false);
    // remove undefined values
    const cleanedFilters = pickBy(filters, identity);

    setFilters({ page: 0, ...cleanedFilters } as AfriexQueryParams);
  }, []);

  useEffect(() => {
    setIsSearch(false);
    fetchList({} as AfriexQueryParams);
  }, [fetchList]);

  const handleUpdate = useCallback(
    async (countryId: string, body: Partial<AfriexCountry>) => {
      try {
        if (!body) {
          return;
        }

        setError(null);
        setIsLoading(true);
        const country = await countryService.saveOne(countryId, body);
        if (country) {
          showSuccessMessage("Country updated successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (e) {
        showErrorMessage(`Updating country failed:${e as any}`);
      }
      setIsLoading(false);
    },
    []
  );

  const handleSearch = useCallback(
    async (searchTerm: string) => {
      if (!searchTerm) {
        setIsSearch(false);
        setFilters({} as AfriexQueryParams);
        fetchList({} as AfriexQueryParams);
        return;
      }
      setIsSearch(true);
      setFilters({
        page: 0,
        status: searchTerm?.trim()?.toString(),
      } as AfriexQueryParams);
    },
    [fetchList]
  );

  const toggleForm = useCallback(() => {
    setShouldShowForm(!shouldShowForm);
  }, [shouldShowForm]);

  return {
    error,
    isLoading,
    isSearch,
    handleUpdate,
    handleSearch,
    handleFilters,
    fetchList,
    page,
    setPage,
    total,
    list,
    item,
    fetchItem,
    shouldShowForm,
    toggleForm,
  };
};

export default useCountryUpdate;

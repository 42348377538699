import { Pagination, TableSkeleton } from "../../../components";
import ReferralTierTable from "./ReferralTierTable";
import TierUpdateButton from "./TierUpdateButton";
import useTierList from "./useTierList";

export const ReferralTierListTab = () => {
  const { isLoading, fetchList, total, list } = useTierList();
  return (
    <main className="my-5">
      <div className="flex justify-end">
        <TierUpdateButton />
      </div>

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <ReferralTierTable items={list} />}
      </section>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as any}
          total={total}
          isSearch={false}
        />
      ) : null}
    </main>
  );
};

import {
  Modal,
  Button,
  SpinningLoader,
  Input,
  CheckBox,
  AmountInput,
  CustomInput,
} from "../../../components";
import { AfriexAmount, AfriexReferralTier } from "../../../types";
import useSignupBonusUpdate from "./useSignupBonusUpdate";
import { TierActionType } from "./types";
import { isEmpty } from "lodash";

type SignupBonusProps = {
  onClose: VoidFunction;
  initialData?: AfriexReferralTier;
};

const SignupBonusForm = ({ onClose, initialData }: SignupBonusProps) => {
  const { dispatch, isLoading, handleSubmit, tier } = useSignupBonusUpdate(
    onClose,
    initialData
  );

  const {
    name,
    isDeactivated,
    referralCode,
    bonusAmountPerReferree,
    currencyCode,
    id: referralId,
  } = tier as AfriexReferralTier;

  const updateAction = {
    type: TierActionType.UPDATE_TIER,
    payload: {},
  };
  const updateLabel = isEmpty(initialData)
    ? "Create New Signup Bonus Tier +"
    : "Update Signup Bonus Tier";
  const submitLabel = isEmpty(initialData) ? "Submit" : "Update";
  const isDisabled = isEmpty(tier);

  return (
    <Modal modalOpen>
      <div className="w-96 my-3 mx-5">
        <h3 className="text-lg  border-bottom">{updateLabel}</h3>

        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(tier as AfriexReferralTier) as any;
              }}
              className="flex flex-col space-y-5"
            >
              <div className="mt-5">
                <CustomInput
                  name="name"
                  label="Name"
                  type="text"
                  value={name ?? ""}
                  className="w-full"
                  onChange={(e) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        name: e.target?.value ?? name,
                      },
                    })
                  }
                />
              </div>
              <div className="mt-5">
                <AmountInput
                  label="Bonus Amount Per Referee"
                  fixedCurrency={referralId ? currencyCode : undefined}
                  value={
                    {
                      amount: bonusAmountPerReferree as any,
                      currency: currencyCode,
                    } as any
                  }
                  onChange={(value: AfriexAmount) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        bonusAmountPerReferree: value?.amount
                          ? Number(value?.amount)
                          : bonusAmountPerReferree,
                        currencyCode: value?.currency
                          ? value?.currency
                          : currencyCode,
                      },
                    })
                  }
                />
              </div>
              <div className="mt-5">
                <CustomInput
                  name="referralCode"
                  label="Referral Code"
                  type="text"
                  value={referralCode ?? ""}
                  className="w-full"
                  onChange={(e) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        referralCode: e.target?.value?.toLowerCase() ?? "",
                      },
                    })
                  }
                />
              </div>

              <div className="mt-5">
                <CheckBox
                  label="Mark as active"
                  type="checkbox"
                  checked={!isDeactivated}
                  onChange={(e: any) =>
                    dispatch({
                      ...updateAction,
                      payload: {
                        isDeactivated: !e.target?.checked ?? isDeactivated,
                      },
                    })
                  }
                />
              </div>

              <div className="flex w-full justify-end space-x-2 py-5">
                <Button
                  type="button"
                  onClick={onClose}
                  variant="outline"
                  colorScheme="cyan"
                >
                  Cancel
                </Button>

                <Button
                  disabled={isDisabled}
                  type="submit"
                  variant="solid"
                  colorScheme="cyan"
                >
                  {submitLabel}
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default SignupBonusForm;

import { ReactElement, useState } from "react";
import { Button } from "../../../components";
import SignupBonusForm from "./SignupBonusForm";
import { isEmpty } from "lodash";
import { AfriexReferralTier } from "../../../types";

type SignupBonusUpdateButtonProps = {
  initialData?: AfriexReferralTier;
};

const SignupBonusUpdateButton = ({
  initialData,
}: SignupBonusUpdateButtonProps): ReactElement => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isEmpty(initialData)
    ? "Create Signup Bonus Code +"
    : "Update Signup Bonus Code";
  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  return showFullScreen ? (
    <SignupBonusForm onClose={toggleFullScreen} initialData={initialData} />
  ) : (
    <Button variant="solid" colorScheme="cyan" onClick={toggleFullScreen}>
      {updateLabel}
    </Button>
  );
};

export default SignupBonusUpdateButton;

import { Pagination, TableSkeleton } from "../../../components";
import SignupBonusTiersTable from "./SignupBonusTiersTable";
import SignupBonusUpdateButton from "./SignupBonusUpdateButton";
import useSignupBonusList from "./useSignupBonusList";

export const SignupBonusListTab = () => {
  const { isLoading, fetchList, total, list } = useSignupBonusList();

  return (
    <main className="my-5">
      <div className="flex justify-end">
        <SignupBonusUpdateButton />
      </div>

      <section className="pt-5 md:pt-5">
        {isLoading ? <TableSkeleton /> : <SignupBonusTiersTable items={list} />}
      </section>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as any}
          total={total}
          isSearch={false}
        />
      ) : null}
    </main>
  );
};

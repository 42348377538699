import { useParams } from "react-router-dom";
import { Pagination } from "../../../components";
import TableSkeleton from "../../../components/transactions/TransactionSkeleton";
import useTierUserList from "./useTierUserList";
import { AfriexPerson } from "../../../types";
import TierUserUpdateButton from "./TierUserUpdateButton";
import UserTable from "../../User/List/UserTable";

const TierUserList = () => {
  const params = useParams();
  const tierId = params?.tierId ?? "";
  const { isLoading, fetchList, total, list } = useTierUserList(tierId);

  return (
    <main className="my-5">
      <div className="flex justify-end">
        <TierUserUpdateButton tierId={tierId} />
      </div>

      <section className="pt-5 md:pt-5">
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <UserTable
            items={list as AfriexPerson[]}
            extraRow={(user) => (
              <div className="w-full flex justify-end mb-3">
                <TierUserUpdateButton tierId={tierId} userId={user?.id} />
              </div>
            )}
          />
        )}
      </section>

      {list && list.length ? (
        <Pagination
          onPageChange={fetchList as any}
          total={total}
          isSearch={false}
        />
      ) : null}
    </main>
  );
};

export default TierUserList;

import { TabItem } from "./types";
import useTabs from "./useTabs";

const activeTabClasses =
  "cursor-pointer inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500";
const inactiveTabClasses =
  "cursor-pointer inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";
const disabledTabClasses =
  "cursor-pointer inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500";
interface TabsProps {
  tabs: TabItem[];
  queryKey?: string;
}

const Tabs = ({ tabs, queryKey }: TabsProps) => {
  const { handleTab, currentTab } = useTabs(tabs, queryKey);

  return (
    <div className="container mx-auto pt-6 pb-12">
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
          {tabs.map((tab) => (
            <li
              className="mr-2"
              onClick={tab.isDisabled ? undefined : () => handleTab(tab)}
              key={tab?.id}
            >
              <span
                className={
                  tab.isDisabled
                    ? disabledTabClasses
                    : currentTab?.id === tab?.id
                      ? activeTabClasses
                      : inactiveTabClasses
                }
                aria-current="page"
              >
                {tab?.title}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div data-content="tab2">{currentTab?.content}</div>
    </div>
  );
};

export default Tabs;

import React, { useState } from "react";
import { Button, CheckBox, ViewModal } from "../../../components";
import { AfriexComplianceDocumentTypes } from "../../../types/AfriexComplianceDocumentTypes";
import { UserDetailsReturnProps } from "./types";
import { AfriexVerificationStatus } from "../../../types";
import { logger } from "../../../utils/logger";
import { toast } from "react-toastify";
import { kycService } from "../../../services/kycService";

const complianceOptions = Object.values(AfriexComplianceDocumentTypes);

type ComplianceChecklistModalProps = Pick<
  UserDetailsReturnProps,
  "user" | "toggleComplianceModal"
>;
const ComplianceChecklistModal = ({
  toggleComplianceModal,
  user,
}: ComplianceChecklistModalProps) => {
  const [selectedOptions, setSelectedOptions] = useState<
    AfriexComplianceDocumentTypes[]
  >([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleCheckboxChange = (option: AfriexComplianceDocumentTypes) => {
    setSelectedOptions((prevState) =>
      prevState.includes(option)
        ? prevState.filter((item) => item !== option)
        : [...prevState, option]
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await kycService.updateKycStatus(
        user.id,
        AfriexVerificationStatus.ReuploadRequested,
        {
          requestedAdditionalDocuments: selectedOptions,
        }
      );
      toggleComplianceModal();
    } catch (error) {
      logger.error("Error updating kyc status:", error);
    } finally {
      setIsSubmitting(false);
      toast.success("Documents request successfully submitted.");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <ViewModal onClose={toggleComplianceModal}>
      <form onSubmit={handleSubmit}>
        <div className="mt-5">
          <h2 className="text-lg font-semibold">
            Request Additional Documents
          </h2>
          <h2 className="text-sm font-light mt-2 mb-10">
            Select the documents you require from the customer from the list
            below
          </h2>
          {complianceOptions.map((option) => (
            <div key={option} className="mt-2">
              <label className="flex items-center">
                <CheckBox
                  label={option
                    .replace(/_/g, " ")
                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                />
              </label>
            </div>
          ))}
        </div>
        <div className="flex w-full justify-end space-x-2 py-5">
          <Button
            colorScheme="cyan"
            variant="outline"
            onClick={toggleComplianceModal}
          >
            Cancel
          </Button>
          <Button type="submit" colorScheme="cyan" disabled={isSubmitting}>
            {isSubmitting ? (
              <div className="flex items-center">
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    className="opacity-25"
                  />
                  <path
                    d="M4 12a8 8 0 0 1 8-8m0 0a8 8 0 0 1 8 8m-8-8v8"
                    stroke="currentColor"
                    strokeWidth="4"
                    className="opacity-75"
                  />
                </svg>
                Submitting...
              </div>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </form>
    </ViewModal>
  );
};

export default ComplianceChecklistModal;

import { capitalize } from "lodash";
import {
  AfriexReferralStatus,
  AfriexTransaction,
  AfriexTransactionStatus,
} from "../types";
import {
  LedgerReasons,
  TransactionChannels,
  TransactionTypes,
} from "../types/Transaction";
import { capitalizeWords, formatMonetaryValue } from "../helpers/dashboard";
import { TransactionProcessors } from "../types/ProcessorTypes";

export const doNothingAsync = () => Promise.resolve();
export const doNothing = () => null;

export const getCountryOptions = (countryOptions: Record<string, string>) =>
  Object.keys(countryOptions).map((key) => {
    const displayKey = countryOptions[key as keyof typeof countryOptions];
    return {
      key: displayKey.replace(/undefined/g, ""),
      value: key,
    };
  });

export const getTransactionChannelsOptions = () =>
  Object.keys(TransactionChannels)
    .map((key) => ({
      key: capitalizeWords(key?.replace(/_/g, " ")),
      value: TransactionChannels[key as keyof typeof TransactionChannels],
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

export const getTransactionProcessorsOptions = () =>
  Object.keys(TransactionProcessors)
    .map((key) => ({
      key: capitalizeWords(key?.replace(/_/g, " ")),
      value: TransactionProcessors[key as keyof typeof TransactionProcessors],
    }))
    .sort((a, b) => a.key.localeCompare(b.key));

export const getTransactionTypesOptions = () =>
  Object.keys(TransactionTypes).map((key) => ({
    key: capitalizeWords(key?.replace(/_/g, " ")),
    value: TransactionTypes[key as keyof typeof TransactionTypes],
  }));

export const getTransactionStatusOptions = () =>
  Object.keys(AfriexTransactionStatus).map((key) => ({
    key: capitalize(key),
    value: AfriexTransactionStatus[key as keyof typeof AfriexTransactionStatus],
  }));

export const getReferralStatusOptions = () =>
  Object.keys(AfriexReferralStatus).map((key) => ({
    key: capitalize(key),
    value: AfriexReferralStatus[key as keyof typeof AfriexReferralStatus],
  }));

export const getLedgerReasonOptions = () => {
  return Object.keys(LedgerReasons).map((key) => {
    const value = LedgerReasons[key as keyof typeof LedgerReasons];
    return {
      key: capitalizeWords(value.replace(/_/g, " ")),
      value,
    };
  });
};

export const getTransactionAmountandAsset = (
  transaction: AfriexTransaction
) => {
  const isWithdrawal = transaction?.type === TransactionTypes.WITHDRAW;
  const isAdmin = transaction.channel === TransactionChannels.ADMIN;
  const amount = (
    isAdmin
      ? transaction.sourceAmount
      : isWithdrawal
        ? transaction.destinationAmount
        : transaction.sourceAmount
  ) as number;
  const asset = isAdmin
    ? transaction.sourceAsset
    : isWithdrawal
      ? transaction.destinationAsset
      : transaction.sourceAsset;

  return { amount: formatMonetaryValue(amount), asset };
};
